var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"justify-center break-word"},[_vm._v(" "+_vm._s(_vm.$t('publishedTenders.title'))+" ")]),_c('v-card',{attrs:{"rounded":"lg","elevation":"0"}},[_c('v-card-title',{staticClass:"ml-4 grey--text"},[_vm._v(" "+_vm._s(_vm.$t(_vm.type === 'buy' ? 'buyingTenders' : _vm.type === 'sell' ? 'sellingTenders' : ''))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items.value,"loading":_vm.loading,"options":_vm.pagination.value,"server-items-length":_vm.totalRecords,"fixed-header":"","outlined":""},on:{"update:options":function($event){return _vm.$set(_vm.pagination, "value", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(((item.publishTime) + " " + (_vm.formatDate(item.publishDate)))))]),_c('td',[_vm._v(_vm._s(((item.endTime) + " " + (_vm.formatDate(item.endDate)))))]),_c('td',[_vm._v(_vm._s(_vm.formatEvaluationTime(item.offerValidityHours, item.offerValidityMinutes)))]),_c('td',[(item.hasOwnBids)?_c('edit-button',{attrs:{"to":{
                name: 'MyTenderOffer',
                params: { id: item.id, viewType: 'edit' },
                query: { redirectedFrom: _vm.redirectedFrom },
              }}}):_c('bid-button',{attrs:{"to":{
                name: _vm.type === 'buy' ? 'MakeBuyingOffer' : _vm.type === 'sell' ? 'MakeSellingOffer' : '',
                params: { id: item.id },
              }}})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }