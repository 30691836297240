
















































import { formatDate, formatEvaluationTime } from '@/utils/helpers'
import { defineComponent, reactive } from '@vue/composition-api'
import type { Header } from '@/components/composables/useDataTable/types'
import { useDataTable } from '@/components/composables/useDataTable'
import BidButton from '@/components/buttons/BidButton.vue'
import PublishedTenderService from '@/services/publishedTender'
import EditButton from '@/components/buttons/EditButton.vue'

export default defineComponent({
  setup({ type }) {
    const searchParams = reactive({ type })
    const { items, totalRecords, loading, pagination, search, query, pages, fetchData } = useDataTable(
      PublishedTenderService,
      searchParams,
    )

    return {
      items,
      totalRecords,
      loading,
      pagination,
      search,
      query,
      pages,
      fetchData,
      formatDate,
      formatEvaluationTime,
      PublishedTenderService,
    }
  },
  props: { type: { type: String, default: '' } },
  name: 'PublishedTendersList',
  components: { EditButton, BidButton },
  computed: {
    headers(): Array<Header> {
      return [
        { value: 'name', text: this.$t('name'), sortable: true },
        { value: 'publishDate', text: this.$t('publishDate'), sortable: true },
        { value: 'endDate', text: this.$t('closureDate'), sortable: true },
        { value: 'offerValidityMinutes', text: this.$t('evaluationDuration'), sortable: true },
        { value: 'actions', text: this.$t('actions'), sortable: false },
      ]
    },
    redirectedFrom(): string {
      return this.type === 'sell' ? 'PublishedSellingTenders' : this.type === 'buy' ? 'PublishedBuyingTenders' : ''
    },
  },
})
